@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";

@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";


.editorClassName {
	display: block;
	background-color: rgb(255, 255, 255);
	border-collapse: separate;
	border-style: solid;
	border-width: 1px;
	clear: none;
	float: none;
	margin: 0px;
	outline: rgb(45, 53, 60) none 0px;
	outline-offset: 0px;
	position: static;
	inset: auto;
	z-index: auto;
	vertical-align: baseline;
	text-align: start;
	box-sizing: border-box;
	box-shadow: none;
	border-radius: 4px;
	height: 232px;
}
