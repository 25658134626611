@import 'facebook/styles';

.react-notification-root {
    top: 10px !important;
}

/* Nvd3 Chart */
.widget-chart-full-width {
	overflow: hidden;
	
	& .nv-chart {
		margin-left: -20px;
		
		& .nvd3-svg {
			height: 110% !important;
		}
	}
}


/* Dropdown Caret */
.dropdown-toggle::after {
	display: none !important;
}

/* Mailbox */
.mailbox {
	& .mailbox-to {
		& .react-tags {
			border: 0;
			flex: 1;
			box-shadow: none !important;
		}
	}
	& .mailbox-input {
		& .quill {
			& .ql-toolbar {
				border: 0;
				border-bottom: 1px solid var(--app-component-border-color);
			}
			& .ql-container {
				border: 0 !important;
			}
		}
	}
}


/* React Calendar */
.react-calendar {
	width: auto;
	min-width: 300px;
	border: none;
	font-family: inherit;
	background: none;
	padding: 5px;
	
	& .react-calendar__navigation {
		height: auto;
		margin-bottom: 0;
		min-height: 30px;
		
		& .react-calendar__navigation__label {
			font-weight: $font-weight-bold;
			font-size: 12px;
			padding: 0;
			color: var(--app-component-color);
			border-radius: $border-radius-lg;
			
			&:hover,
			&:focus {
				background: var(--app-component-hover-bg);
				outline: none;
			}
			&[disabled] {
				background: none;
				color: rgba(var(--app-component-color-rgb), .5);
			}
		}
		& .react-calendar__navigation__arrow {
			color: transparent;
			position: relative;
			padding: 0;
			color: rgba(var(--app-component-color-rgb), .5);
			border-radius: $border-radius-lg;
			
			@include fontawesome();
			
			&:hover,
			&:focus {
				background: var(--app-component-hover-bg);
				outline: none;
			}
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				color: rgba(var(--app-component-color-rgb), .5);
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&.react-calendar__navigation__prev2-button:after {
				content: '\f100';
			}
			&.react-calendar__navigation__prev-button:after {
				content: '\f104';
			}
			&.react-calendar__navigation__next2-button:after {
				content: '\f101';
			}
			&.react-calendar__navigation__next-button:after {
				content: '\f105';
			}
		}
	}
	& .react-calendar__month-view {
		& .react-calendar__month-view__weekdays {
			& .react-calendar__month-view__weekdays__weekday {
				min-height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				
				& abbr {
					font-size: 12px;
					text-decoration: initial;
					font-weight: bold;
					text-transform: initial;
				}
			}
		}
		& .react-calendar__month-view__days {
			& .react-calendar__tile {
				height: auto;
				min-height: 30px;
				color: rgba(var(--app-component-color-rgb), .5);
				font-weight: $font-weight-bold;
				border-radius: $border-radius-lg;
			
				&:hover,
				&:focus {
					background: var(--app-component-hover-bg);
					color: var(--app-component-color);
					outline: none;
				}
				&.react-calendar__tile--active {
					background: var(--app-component-active-bg);
					color: var(--app-component-active-color);
				}
			}
		}
	}
	& .react-calendar__year-view,
	& .react-calendar__decade-view,
	& .react-calendar__century-view {
		& .react-calendar__tile {
			height: auto;
			min-height: 50px;
			color: rgba(var(--app-component-color-rgb), .5);
			font-weight: $font-weight-bold;
			padding: 0;
			border-radius: $border-radius-lg;
		
			&:hover,
			&:focus {
				background: var(--app-component-hover-bg);
				color: var(--app-component-color);
				outline: none;
			}
			&.react-calendar__tile--active,
			&.react-calendar__tile--hasActive {
				background: var(--app-component-active-bg);
				color: var(--app-component-active-color);
			}
		}
	}
}


/* Highlight.js */
.hljs {
	& .hljs-keyword,
	& .hljs-built_in {
		color: darken($blue, 15%);
	}
	& .hljs-class {
		color: lighten($blue, 5%);
	}
	& .hljs-string,
	& .hljs-literal {
		color: darken($red, 7.5%);
	}
	& .hljs-title {
		color: lighten($dark, 20%);
	}
}


/* Countdown */
.countdown-row {
	clear: both;
	width: 100%;
	padding: 0px 2px;
	text-align: center;
}
.countdown-section {
	display: block;
	float: left;
	font-size: 75%;
	text-align: center;
	
	& .countdown-period {
		display: block;
	}
	& .countdown-descr {
		display: block;
		width: 100%;
	}
	.countdown-show1 & {
		width: 98%;
	}
	.countdown-show2 & {
		width: 48%;
	}
	.countdown-show3 & {
		width: 32.5%;
	}
	.countdown-show4 & {
		width: 24.5%;
	}
	.countdown-show5 & {
		width: 19.5%;
	}
	.countdown-show6 & {
		width: 16.25%;
	}
	.countdown-show7 & {
		width: 14%;
	}
}


/* Rdt Datepicker */
.react-datepicker-wrapper {
	display: block;
}
.rdt {
	& .rdtPicker {
		width: auto;
		background: var(--app-component-dropdown-bg);
		border: none;
		border-radius: $border-radius-lg;
		box-shadow: 0 0.5rem 1rem rgba($black,.175);
		
		& table {
			& thead {
				& tr {
					& th {
						&.rdtPrev,
						&.rdtNext,
						&.rdtSwitch {
							line-height: 20px;
							padding: 5px;
							height: auto;
							min-height: 30px;
							border: none;
							border-radius: $border-radius;
							
							&:hover,
							&:focus {
								background: var(--app-component-dropdown-hover-bg);
								
								& span:before {
									color: var(--app-component-color);
								}
							}
						}
						&.rdtPrev,
						&.rdtNext {
							color: transparent;
							width: 14.28%;
							position: relative;
							
							& span {
								color: transparent;
								position: relative;
								display: block;
								border-radius: $border-radius-lg;
								
								@include fontawesome();
							
								&:before {
									color: rgba(var(--app-component-color-rgb), .5);
									position: absolute;
									font-size: 14px;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									line-height: 1;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								& i {
									display: none;
								}
							}
						}
						&.rdtPrev {
							& span:before {
								content: '\f104';
							}
						}
						&.rdtNext {
							& span:before {
								content: '\f105';
							}
						}
						&.rdtSwitch {
							width: auto;
						}
						&.dow {
							padding: 5px 0;
						}
					}
				}
			}
			& tfoot {
				border: none;
				
				& tr {
					& td {
						&.rdtTimeToggle {
							border: none;
							font-weight: $font-weight-bold;
							color: rgba(var(--app-component-color-rgb), .5);
							text-shadow: none;
							border-radius: $border-radius-lg;
				
							&:hover,
							&:focus {
								background: var(--app-component-dropdown-hover-bg);
								text-shadow: none;
							}
						}
					}	
				}
			}
		}
		& .rdtTime {
			min-width: 180px;
			
			& .rdtCounters {
				display: flex;
				align-items: center;
				justify-content: center;
				
				& > div {
					float: none;
				}
				& .rdtCounter {
					height: 110px;
					width: 60px;
					
					& .rdtBtn {
						height: 35%;
						color: transparent;
						position: relative;
						border-radius: $border-radius-lg;
						
						@include fontawesome();
						
						&:before {
							content: '\f106';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							color: rgba(var(--app-component-color-rgb), .5);
							font-size: 18px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						&:hover,
						&:focus {
							background: var(--app-component-dropdown-hover-bg);
						}
					}
					& .rdtCount {
						height: 30%;
						font-weight: $font-weight-bold;
						color: rgba(var(--app-component-color-rgb), .75);
						font-size: 13px;
						display: flex;
						align-items: center;
						justify-content: center;
						
						& + .rdtBtn {
							&:before {
								content: '\f107';
							}
						}
					}
				}
			}
		}
		& .rdtDays {
			min-width: 290px;
			
			& .rdtDay {
				font-weight: $font-weight-bold;
				color: rgba(var(--app-component-color-rgb), .75);
				padding: 5px;
				line-height: 20px;
				border-radius: $border-radius-lg;
				
				&:hover,
				&:focus,
				&.rdtSelected,
				&.rdtHighlighted,
				&.rdtRange {
					background: var(--app-component-dropdown-hover-bg) !important;
					text-shadow: none;
				}
				&.rdtToday {
					background: rgba(var(--app-component-bg-rgb), .5) !important;
					color: var(--app-component-color) !important;
				}
				&.rdtActive {
					background: var(--app-component-active-bg) !important;
					color: var(--app-component-active-color) !important;
					text-shadow: none !important;
				}
				&.rdtOld {
					color: rgba(var(--app-component-color-rgb), .25);
				}
				&.rdtNew {
					color: rgba(var(--app-component-color-rgb), .5);
				}
				&.disabled {
					color: rgba(var(--app-component-color-rgb), .25);
					
					&:hover,
					&:focus {
						background: none;
					}
				}
			}
		}	
		& .rdtMonths,
		& .rdtYears {
			min-width: 290px;
			
			& .rdtMonth,
			& .rdtYear {
				font-weight: $font-weight-bold;
				color: rgba(var(--app-component-color-rgb), .5);
				text-shadow: none;
				border-radius: $border-radius;
				
				&:hover,
				&:focus {
					background: var(--app-component-dropdown-hover-bg);
				}
				&.rdtFocused {
					background: var(--app-component-dropdown-hover-bg);
					color: var(--app-component-color);
				}
				&.rdtActive {
					background: var(--app-component-active-bg) !important;
					color: var(--app-component-active-color);
				}
			}
		}
	}
}


/* React Datepicker */
.react-datepicker {
	border: none;
	display: block;
	font-family: inherit;
	background: none;
	
	.react-datepicker-popper & {
		padding: 5px;
		background: var(--app-component-dropdown-bg);
		z-index: 10;
		border-radius: $border-radius-lg;
		box-shadow: 0 0.5rem 1rem rgba($black,.175);
	}
	& .react-datepicker__triangle {
		display: none;
	}
	& .react-datepicker__navigation {
		color: transparent;
		padding: 5px;
		line-height: 20px;
		border: none;
		width: 30px;
		height: 30px;
		top: 0;
		text-indent: 0;
		border-radius: $border-radius-lg;
		
		&:hover,
		&:focus {
			background: var(--app-component-hover-bg);
			outline: none;
			
			&:after {
				color: var(--app-component-hover-color);
			}
		}
		&:after {
			color: rgba(var(--app-component-color-rgb), .5);
			position: absolute;
			font-size: 14px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			
			@include fontawesome();
		}
		& .react-datepicker__navigation-icon {
			display: none;
		}
		&.react-datepicker__navigation--previous {
			&:after {
				content: '\f104';
			}
		}
		&.react-datepicker__navigation--next {
			&:after {
				content: '\f105';
			}
		}
	}
	& .react-datepicker__month-container {
		float: none;
		
		& .react-datepicker__header {
			background: none;
			border: none;
			padding: 0;
			
			& .react-datepicker__current-month {
				font-size: 12px;
				color: var(--app-component-color);
				font-weight: 700;
				padding: 5px;
				line-height: 20px;
			}
			& .react-datepicker__day-names {
				display: flex;
				
				& .react-datepicker__day-name {
					font-weight: $font-weight-bold;
					color: rgba(var(--app-component-color-rgb), .5);
					font-size: 12px;
					padding: 5px;
					line-height: 20px;
					margin: 0;
					flex: 1;
				}
			}
		}
		
		& .react-datepicker__month {
			margin: 0;
			
			& .react-datepicker__week {
				display: flex;
				
				& .react-datepicker__day {
					padding: 5px;
					line-height: 20px;
					font-weight: 600;
					color: rgba(var(--app-component-color-rgb), .5);
					flex: 1;
					border-radius: $border-radius-lg;
					
					&:hover,
					&:focus,
					&.react-datepicker__day--today {
						background: var(--app-component-hover-bg);
						color: var(--app-component-color);
						outline: none;
					}
					&.react-datepicker__day--selected {
						background: var(--app-component-active-bg);
						color: var(--app-component-active-color);
					}
				}
			}
		}
	}
}


/* React Tag Autocomplete */
.react-tags {
	min-height: 34px;
	border: 1px solid var(--app-component-border-color);
	padding: 5px 12px;
	border-radius: $border-radius;
	display: flex;
	flex-wrap: wrap;
	transition: all .2s linear;
	
	.email-to & {
		border-left: none;
		border-right: none;
		border-top: none;
		padding-left: 50px;
		min-height: 43px;
		align-items: center;
		border-radius: 0;
		
		&.is-focused {
			box-shadow: none;
		}
	}
	&.is-focused {
		border-color: var(--app-component-focus-border-color);
		box-shadow: 0 0 0 0.2rem rgba($blue,.25);
	}
	& .react-tags__selected {
		& .react-tags__selected-tag {
			background: var(--app-component-active-bg);
			color: var(--app-component-active-color);
			padding: 2px 25px 2px 10px;
			border: none;
			outline: none;
			font-weight: $font-weight-bold;
			font-size: 11px;
			margin: 2px;
			position: relative;
			border-radius: $border-radius;
			
			&:after {
				content: '\f00d';
				position: absolute;
				right: 2px;
				top: 0;
				bottom: 0;
				width: 20px;
				font-size: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				
				@include fontawesome();
			}
		}
	}
	& .react-tags__search {
		position: relative;
		
		& .react-tags__search-input {
			border: none;
			background: none;
			height: 20px;
			outline: none;
			margin: 2px 5px;
			font-weight: $font-weight-bold;
			color: var(--app-component-color);
			min-width: rem(150px);
			box-shadow: none;
			
			&::placeholder {
				color: rgba(var(--app-component-color-rgb), .5);
			}
		}
		& .react-tags__suggestions {
			position: absolute;
			top: 100%;
			left: 0;
			background: var(--app-component-dropdown-bg);
			padding: 10px 0;
			min-width: 120px;
			border-radius: $border-radius-lg;
			box-shadow: 0 0.5rem 1rem rgba($black,.175);
			
			& ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				
				& li {
					display: block;
					padding: 5px 10px;
					
					&:hover,
					&:focus {
						background: var(--app-component-dropdown-hover-bg);
					}
					& span {
						font-weight: $font-weight-bold;
						color: rgba(var(--app-component-color-rgb), .5);
						
						& mark {
							background: lighten($yellow, 20%);
							color: dark;
							padding: 0 2px;
							border-radius: $border-radius;
						}
					}
				}
			}
		}
	}
}


/* React Color */
.sketch-picker,
.chrome-picker {
	background: none !important;
	border: none !important;
	box-shadow: none !important;
	
	.dropdown-menu & {
		margin: -8px 0;
	}
	
	& label {
		color: var(--app-component-color) !important;
	}
	& input {
		border: 1px solid var(--app-component-border-color) !important;
		background: var(--app-component-bg) !important;
		color: var(--app-component-color) !important;
		transition: all .2s linear;
		box-shadow: none !important;
		border-radius: $border-radius;
		
		&:focus {
			border-color: var(--app-component-focus-border-color) !important;
			outline: none;
			box-shadow: 0 0 0 0.2rem rgba($blue,.25) !important;
		}
	}
	& .flexbox-fix {
		border-color: var(--app-component-border-color) !important;
	}
}


/* React Slider */
.rc-slider {
	& .rc-slider-rail {
		background: var(--bs-light);
	}
	& .rc-slider-track {
		background: var(--app-component-active-bg);
	}
	& .rc-slider-dot {
		border-color: var(--bs-light);
		background-color: rgba(var(--app-component-color-rgb), .5);
		
		&.rc-slider-dot-active {
			border-color: var(--app-component-active-bg);
		}
	}
	& .rc-slider-handle {
		border-color: var(--app-component-active-bg);
		background-color: var(--app-component-active-bg);
		
		&:focus,
		&:active {
			box-shadow: 0 0 0 3px rgba(var(--app-component-active-bg-rgb), .25);
		}
	}
}
.rc-slider-tooltip {
	& .rc-slider-tooltip-content {
		margin-top: 10px;
		
		& .rc-slider-tooltip-inner {
			background: var(--app-component-dropdown-bg);
			font-size: 11px;
			font-weight: $font-weight-bold;
			padding: 0 10px;
			line-height: 24px;
			box-shadow: none;
		}
	}
	&.rc-slider-tooltip-placement-top {
		& .rc-slider-tooltip-arrow {
			border-top-color: var(--app-component-dropdown-bg);
		}
	}
}


/* React Table */
.ReactTable {
	border: none;
	
	& .rt-table {
		& .rt-thead {
			&.-headerGroups {
				background: none;
				border: none;
			}
			&.-header {
				box-shadow: none;
			}
			&.-filters {
				background: $light;
				border-bottom: 1px solid lighten($border-color, 5%);
				
				& input,
				& select {
					border: 1px solid $border-color;
					padding: 7px 12px;
					border-radius: $border-radius;
					transition: all .2s linear;
					
					&:focus {
						border-color: $input-focus-border-color;
						box-shadow: 0 0 0 0.2rem rgba($blue,.25);
					}
				}
			}
			& .rt-tr {
				& .rt-resizable-header {
					&.rt-th,
					&.rt-td {
						position: relative;
						padding-right: 25px;
					
						&:after {
							content: '\f0dc';
							position: absolute;
							width: 25px;
							top: 0;
							right: 0;
							bottom: 0;
							color: mix($white, $dark, 75%);
							font-size: 14px;
							display: flex;
							align-items: center;
							justify-content: center;
						
							@include fontawesome();
						}
						&:hover,
						&:focus {
							&:after {
								color: mix($white, $dark, 50%);
							}
						}
						&.-sort-desc {
							box-shadow: none;
							
							&:after {
								content: '\f0dd';
								color: var(--app-component-active-bg);
							}
						}
						&.-sort-asc {
							box-shadow: none;
							
							&:after {
								content: '\f0de';
								
								color: var(--app-component-active-bg);
							}
						}
					}
				}
				& .rt-th {
					padding: 8px;
					font-weight: 600;
					border-color: lighten($border-color, 5%);
				}
			}
			& + .rt-thead {
				border-top: 1px solid lighten($dark, 55%);
			}
		}
		& .rt-tbody {
			& .rt-tr-group {
				border: none;
				
				& .rt-tr {
					& .rt-td {
						padding: 8px;
						border-color: lighten($border-color, 5%);
					}
				}
				& + .rt-tr-group {
					border-top: 1px solid lighten($border-color, 5%);
				}
			}
		}
	}
	& .pagination-bottom {
		& .-pagination {
			border-top: 1px solid lighten($border-color, 5%);
			padding: 8px;
			box-shadow: none;
			
			& .-btn {
				font-weight: 600;
				color: $dark;
				background: lighten($border-color, 10%);
				border-radius: $border-radius-lg;
				
				&:hover,
				&:focus {
					background: lighten($border-color, 5%);
					color: $dark;
				}
				&[disabled] {
					&:hover,
					&:focus {
						background: lighten($border-color, 10%);
					}
				}	
			}
			& .-pageInfo {
				margin: 0;
			}
			& input,
			& select {
				border: 1px solid $border-color;
				padding: 7px 12px;
				border-radius: $border-radius;
				transition: all .2s linear;
				
				&:focus {
					border-color: $input-focus-border-color;
					box-shadow: 0 0 0 0.2rem rgba($blue,.25);
				}
			}
		}
	}
}


/* Boxed Layout */
.boxed-layout {
	#root {
		height: 100%;
	}
}


/* Sweet Alert */
.sweet-alert {
	& .btn {
		box-shadow: none !important;
	}
}


/* React Notification */
.notifications-component  {
	top: 0;
	left: 0;
}

.scrollbar-container {
	.app:not(.app-sidebar-fixed) & {
		height: auto;
	}
}