@import 'mixins/background-gradient';
@import 'mixins/clearfix';
@import 'mixins/fontawesome';
@import 'mixins/gradient-enabled';
@import 'mixins/helper-color';
@import 'mixins/helper-font-size';
@import 'mixins/helper-font-weight';
@import 'mixins/helper-opacity';
@import 'mixins/helper-row-space';
@import 'mixins/helper-size';
@import 'mixins/helper-spacing';


.form-check-input-pos:checked {
    background-color: #0f9e3e;
    border-color: #0f9e3e;
}